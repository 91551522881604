<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                {{ $t("message.department_title") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="12" class="flex-style text-right mbtop5">
            <crm-create-and-column-settings
              :permission="$options.name"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'DepartmentController@store'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <el-row :gutter="20">
          <el-col :xs="22" :sm="6">
            <draggable
                    class="dragArea ali2"
                    tag="ul"
                    :list="list"
                    :group="{ name: 'g1' }"
                    @change="log($event)"
                >
              <li v-for="el in list" :key="el.id">
                  <div class="itme-blocks">{{ el.name }}</div>
              </li>
          </draggable>
           
          </el-col>
          <el-col :xs="2" :sm="18">
            <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
              <thead>
                <tr>

                  <th v-if="columns.name.show">
                    {{ columns.name.title }}
                  </th>
                  <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                  </th>

                  <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                  </th>

                  <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                  </th>
                </tr>

                <tr class="filter_sorche">
                  <th v-if="columns.name.show">
                    <crm-input
                      :placeholder="columns.name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.name"
                    ></crm-input>
                  </th>
                  <th v-if="columns.created_at.show">
                    <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                    ></crm-date-picker>
                  </th>

                  <th v-if="columns.updated_at.show">
                    <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                    ></crm-date-picker>
                  </th>

                  <th class="settinW" v-if="columns.settings.show"></th>
                </tr>
              </thead>

              <transition-group name="flip-list" tag="tbody">
                <tr
                  v-for="department in list"
                  :key="department.order + 'departments'"
                  class="cursor-pointer"
                >
                  <td v-if="columns.name.show">
                    {{ department.name }}
                  </td>

                  <td v-if="columns.created_at.show">
                    {{ department.created_at }}
                  </td>

                  <td v-if="columns.updated_at.show">
                    {{ department.updated_at }}
                  </td>

                  <td v-if="columns.settings.show" class="settings-td">
                    <crm-settings
                      :name="$options.name"
                      :model="department"
                      :permissionShow="'DepartmentController@update'"
                      :permissionDestroy="'DepartmentController@destroy'"
                      :actions="actions"
                      @edit="edit"
                      @delete="destroy"
                    ></crm-settings>
                  </td>
                </tr>
              </transition-group>
            </table>
          </el-col>
        </el-row>

        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>

    <div class="app-modal app-modal__full">
      <el-drawer
        :with-header="false"
        :visible.sync="drawerCreate"
        size="70%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create ref="drawerCreateChild" drawer="drawerCreate"></crm-create>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :visible.sync="drawerUpdate"
        size="70%"
        ref="drawerUpdate"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
      >
        <crm-update
          :selectedItem="selectedItem"
          ref="drawerUpdateChild"
          drawer="drawerUpdate"
        ></crm-update>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import draggable from "vuedraggable";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "DepartmentController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate,
    draggable
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      list: "candidateStep/list",
      columns: "candidateStep/columns",
      pagination: "candidateStep/pagination",
      filter: "candidateStep/filter",
      sort: "candidateStep/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "candidateStep/index",
      setPagination: "candidateStep/setPagination",
      updateSort: "candidateStep/updateSort",
      updateFilter: "candidateStep/updateFilter",
      updateColumn: "candidateStep/updateColumn",
      updatePagination: "candidateStep/updatePagination",
      updateOrder: "candidateStep/updateOrder",
      show: "candidateStep/show",
      empty: "candidateStep/empty",
      delete: "candidateStep/destroy",
      refreshData: "candidateStep/refreshData",
    }),
    log: function(evt, index) {
      // if(evt.added){
      //   let data = {
      //     vacancy_id: this.vacancy.id,
      //     candidate_id: evt.added.element.id,
      //     step_status:index
      //   }
      //   this.loadingData = true;

      //   this.updateCandidate(data)
      //   .then((res) => {
      //     // this.$alert(res);
      //     // console.log(this.list);
      //   this.loadingData = false;

      //   })
      //   .catch((err) => {
      //     // alert(err);
      //   this.loadingData = false;

      //   });
      // }else if(evt.moved){

      // }
       if(evt.moved){

          this.updateOrder(this.list)
            .then((res) => {
            this.loadingData = false;

          })
         window.console.log(evt);
       }
    },
  },
};
</script>

<style lang="scss">
.dragArea {
    min-height: 50px;
    // outline: 1px dashed;
    li > ul {
        margin-left: 20px;
        min-height: 0px;
        outline: 1px;
        .itme-blocks {
            padding: 10px 7px;
            margin-bottom: 5px;
            border: 1px solid #c7c4c4;
            border-radius: 2px;
            color: #7d7d7d;
            font-size: 16px;
            cursor: all-scroll;
        }
    }
    .itme-blocks {
        padding: 10px 7px;
        margin-bottom: 7px;
        border: 1px solid #0d67c9;
        background: #0d67c9;
        border-radius: 6px;
        color: #fff;
        font-size: 16px;
        cursor: all-scroll;
    }
}
</style>
